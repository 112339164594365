//--------- DEFAULT LIGHT MODE ----------//

:root {
  // Brand
  --logo: url("./../../assets/images/logo-beam.svg");
  --color-page-bg: var(--pink);
  --color-art: #0051ff;

  --color-red-dark: #d03939;
  --color-red-sunset: #ee3d4a;
  --color-orange-dark: #ef7026;
  --color-orange-sunset: #f79d69;
  --color-yellow: #fdcf4d;
  --color-grey-blue: #797d94;

  // Fonts
  --font-display: "DIN Next Rounded Bold", sans-serif;
  --font-primary: "DIN Next Rounded Medium", sans-serif;
  --font-secondary: "DIN Next Rounded Light", sans-serif;
  --font-body: "Roboto", sans-serif;

  // Header
  --color-header: var(--white);
  --color-header-text: var(--black);
  --color-page-header: var(--black);
  --color-page-header-arrow: var(--color-orange-dark);

  // Nav
  --color-sidenav-bg: #fafafa;

  // Body
  --color-body-bg: var(--white);
  --color-body-text: var(--black);
  --color-body-text-alt: var(--grey-blue);
  --color-body-text-inverse: var(--white);

  --color-secondary-inverse: var(--secondary-white);
  --color-secondary-body-bg: var(--grey-light);
  --color-secondary-body-text: var(--secondary-black);

  --color-tertiary-text: var(--black-60);
  --color-home-header: var(--black);

  // Body dark bg (worker portal)
  --color-locked-text: var(--white);
  --color-locked-line: var(--darker-line);

  // Buttons
  --color-btn-primary: var(--orangey-red);
  --color-btn-primary-text: var(--white);

  // Lines
  --color-line: var(--light-line);
  --color-line-light: var(--lighter-line);

  // Statuses
  --color-error: var(--red);
  --color-warning: var(--yellow);
  --color-success: var(--green);
  --color-info: var(--blue-md);
  --color-success-wcag: var(--green);

  // Input
  --color-input-bg: var(--off-white);
  --color-highlight: var(--teal);

  // Spinner
  --color-spinner: var(--orangey-red);

  // Chevron colours
  --color-chevron-progress: var(--blue-on-light);
  --color-chevron-pending: var(--yellow);
  --color-chevron-completed: var(--green);
  --color-chevron-progress-text: var(--white);
  --color-chevron-pending-text: var(--black);
  --color-chevron-completed-text: var(--white);

  // Misc
  --color-hover: rgba(0, 0, 0, 0.1);
  --color-hover-highlight: var(--teal-light);
  --color-link: var(--link);

  // Background images
  --hamburger: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgb%255, 255, 255%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
