.nav-buttons {
  margin: 0 -1rem 2rem;
  padding: 1.5rem 1rem 0;
  border-top: 1px solid var(--light-line);
  @media (min-width: $bp-sm) {
    border-top: 0;
  }

  .button {
    display: block;
    width: 100%;
    margin-bottom: 0.25rem;

    @media (min-width: $bp-sm) {
      display: inline-block;
      width: 12rem;
      margin-right: 1rem;
    }
  }
}
