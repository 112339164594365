.pill {
  display: inline-block;
  padding: 0.25rem 1rem;
  font-weight: 500;
  font-size: rem(12px);
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 1px;
  border-radius: 1rem;
  color: var(--grey-dark);
  font-family: var(--font-primary);
}

.pill--in-progress {
  background: var(--color-warning);
  // border: 1px solid var(--color-warning);
}

.pill--completed {
  background: var(--color-success);
  color: #fff;
}

.pill--locked {
  display: inline-flex;
  border: 1px solid var(--color-line);
  align-items: center;
  justify-content: center;
  color: var(--color-secondary-body-text);

  .pill__icon {
    margin-right: 0.25rem;
  }
}
