.info-panel {
  @include radius();
  padding: 1rem;
  background-color: var(--black-05);
  word-break: break-word; // Prevent email from overflow

  p {
    margin-bottom: 0;

    & + * {
      margin-top: 0.5rem;
    }
  }

  a {
    color: var(--color-link);
  }
}

.info-panel__heading {
  margin-bottom: 1rem;
  font-size: 1.125rem;
}

.info-panel__icon {
  @include size(1.25rem);
  margin-right: 1rem;
  color: #333;
  flex-shrink: 0;
  svg {
    width: 20px;
    height: 20px;
  }
}

.info-panel--sm {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;

  .info-panel__heading {
    font-size: 1rem;
  }

  p {
    font-size: rem(14px);

    & + * {
      margin-top: 0.5rem;
    }
  }
}

.info-panel--success {
  .info-panel__icon {
    color: var(--color-success);
  }
}

.info-panel--pending {
  .info-panel__icon {
    color: var(--color-body-text);
  }
}

.info-panel--error {
  .info-panel__icon {
    color: var(--color-error);
  }
}
