.super-choice-option {
  @include radius();
  position: relative;
  display: block;
  margin-bottom: 1rem;
  padding: 1.25rem 1rem;
  border: 2px solid var(--color-line);
  background: #fff;
  overflow: hidden;

  &.is-nominated {
    border: 2px solid var(--color-success);
    background: var(--white);

    .nominate-button {
      border: none;
      background: var(--color-success);
      color: var(--white);
    }
  }

  .label--heading {
    display: block;
    margin-bottom: 1rem;
    font-family: var(--font-primary);
    font-size: 14px;

    text-transform: uppercase;
    color: var(--color-secondary-body-text);
    letter-spacing: 1px;
  }

  .nominate-button {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    border-width: 2px;
    background-color: var(--white);

    &:hover,
    &:focus {
      color: var(--color-body-text-inverse);
      background-color: var(--color-body-text);
    }

    &.is-nominated {
      padding-left: 0.5rem;
      border: none;
      background: var(--color-success);

      &:disabled {
        opacity: 1;
      }
    }
  }
}

.super-choice-option--art {
  background: var(--color-art);
  color: var(--color-body-text-inverse);
  border: 0;

  .label--heading {
    color: var(--color-body-text-inverse);
    opacity: 0.7;
    font-family: "DIN Next Rounded";
  }

  .p--sm {
    color: var(--color-inverse);
  }

  .button--link {
    padding-left: 0;
    padding-right: 0;
    color: var(--color-body-text-inverse);
    font-weight: 700;
    text-decoration: underline;
    display: inline-block;

    &:hover,
    &:focus {
      color: var(--color-body-text-inverse);
    }
  }

  .nominate-button {
    color: var(--color-body-text-inverse);
    background-color: transparent;
    border-color: var(--color-body-text-inverse);

    &:hover,
    &:focus {
      color: var(--color-art);
      background-color: var(--color-body-text-inverse);
    }

    &.is-nominated {
      border: 2px solid var(--white);
      background-color: transparent;
      color: var(--white);
    }
  }
}

.super-choice-radio {
  opacity: 0;
  position: absolute;

  + label {
    width: 100%;
    margin-bottom: 0;
    // font-size: 1.125rem;
    font-weight: 700;
    cursor: pointer;
  }
}

.continue-button {
  margin-top: 10px;
  float: right;

  .continue-button__icon {
    width: auto;
    height: 1rem;
    margin-left: 10px;
  }
}

.selected-fund {
  @include radius;
  padding: 1rem;
  background-color: var(--black-05);
}

.default-funds {
  margin-left: -1rem;
  margin-right: -1rem;

  li {
    padding: 1rem;
    border-top: 1px solid var(--color-line);

    &:only-child {
      border-top: 0;
      padding-top: 0;
      padding-bottom: 1.5rem;
    }

    &:not(:only-child).is-selected {
      background: var(--black-05);
    }
  }
}

.logo-art {
  margin: 0.5rem 0;
  @media (min-width: $bp-sm) {
    margin: 1rem 0;
  }
}

.list--art {
  margin-bottom: 1.5rem;
  li {
    font-family: var(--font-secondary);
    font-size: 18px;
    background-image: url(../../assets/images/icon-tick-art.svg);
    background-repeat: no-repeat;
    background-position: left top;
    padding-left: 2.3rem;
    margin-bottom: 0.5rem;
    @media (min-width: $bp-sm) {
      font-size: 20px;
    }
    .btn {
      padding: 0 4px;
    }
  }
}

.account-confirmation {
  .title,
  .h1 {
    color: var(--color-body-text);
  }
}

.list--terms-and-conditions {
  margin-left: 1rem;
  list-style: disc;

  li {
    margin-bottom: 1rem;
  }
}
