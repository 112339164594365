.form-container {
  overflow: hidden;
}

.form {
  @include clearfix;

  fieldset {
    @extend .panel;
  }

  legend {
    @include clearfix;
    @extend .h2;
    float: left;
    color: var(--color-body-text);
  }

  .form-group + .form-group {
    margin-top: 1rem;

    @media (min-width: $bp-md) {
      margin-top: 1.5rem;
    }
  }

  .form-label {
    font-weight: 700;
    color: var(--color-secondary-body-text);
  }

  .form-check {
    margin-bottom: 0.3rem;
  }

  // Override default hint text margin to place it between label and input
  .form-text {
    margin-top: -0.5rem;
    margin-bottom: 0.5rem;
  }

  .input-range {
    width: 200px;
    margin: 0.5rem auto 2rem;
  }

  ::placeholder {
    font-style: italic;
  }

  .button-comp {
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
  }

  .hint {
    color: var(--color-secondary-body-text);
  }

  .custom-radio,
  .custom-checkbox {
    margin-bottom: 0.5rem;
  }

  .invalid-feedback {
    color: var(--color-error);
  }

  //calendar
  .DayPickerInput {
    display: block;
  }

  .DayPickerInput-Overlay {
    background: var(--color-body-bg);
  }

  .DayPicker-Day {
    &:hover {
      background-color: var(--color-hover) !important;
    }
  }
}

fieldset {
  font-size: rem(16px);
  border: 1px solid var(--color-line);
}

$form-input-height: calc(2em + 0.75rem + 2px);

// Moved out of .form scope to reduce specificity so styles of other varaitions can be applied
.form-control {
  height: $form-input-height;
  // background: var(--color-test);
  border: 1px solid var(--color-line);
  line-height: 1;
  color: var(--color-body-text);
  background-color: var(--color-input-bg);

  &:focus {
    background: var(--color-body-bg);
    color: var(--color-body-text);
  }

  &:read-only {
    cursor: not-allowed;
    border: 1px solid var(--color-line-light);
    background: var(--color-input-bg);
    color: var(--color-secondary-body-text);

    &:focus {
      box-shadow: none;
      border: 1px solid var(--color-line-light);
      background: var(--color-input-bg);
    }
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
    background: var(--color-input-bg);
  }

  &.is-invalid {
    border-color: var(--color-error);
  }
}

textarea.form-control {
  line-height: 1.5rem;
  min-height: 10rem;
}

// A variation to reserve the label space above
.form-control--no-label {
  margin-top: 2rem;
}
.form-control--no-label-2lines {
  margin-top: 3.3rem;
}

.form-control-container {
  position: relative;

  .form-control-prepend {
    position: absolute;
    left: 0.75rem;
    top: 0;
    width: 4rem;
    height: $form-input-height;
    line-height: 1;
    display: flex;
    align-items: center;
    color: var(--color-secondary-body-text);
  }

  .form-control-prepend__flag {
    width: 1.5rem;
  }

  .form-control {
    padding-left: 4.5rem;
  }
}

// Custom class name on react-select
.form-select-container {
  .form-select__control {
    background-color: var(--color-input-bg);
    color: var(--color-body-text);
    min-height: $form-input-height;
    border: 1px solid var(--color-line);
    cursor: pointer;
  }

  .form-select__menu-list,
  .form-select__single-value,
  .form-select__option {
    background-color: var(--color-input-bg);
    color: var(--color-body-text);
  }

  &.is-invalid {
    .form-select__control {
      border-color: var(--color-error);
    }
  }
}

.form-select--is-disabled {
  opacity: 0.5;
}

// Detached dropdown that is outside of form-select-container
.form-select__menu.form-select__menu {
  border: 1px solid var(--color-line);
  background-color: var(--color-input-bg);
  color: var(--color-body-text);

  .form-select__menu-list,
  .form-select__single-value,
  .form-select__option {
    background-color: var(--color-input-bg);
    color: var(--color-body-text);
  }

  .form-select__option--is-focused {
    background-color: var(--color-line-light);
    cursor: pointer;
  }

  .form-select__option--is-selected {
    background-color: var(--color-line-light);
  }
}

.form-select__value-container {
  .form-select__multi-value__remove {
    .dark-mode & {
      svg {
        color: var(--black);
      }
    }
  }
}

.super-fund-select {
  .form-select__value-container {
    height: 100%;
    line-height: 1.25;
  }

  .form-select__option {
    line-height: 1.25;
  }

  .form-select__single-value .extra-info {
    display: none;
  }
}

.valid-check {
  background-color: var(--color-line-light);
  @include radius;
  padding: 1rem;
  margin-top: 1rem;
  color: var(--secondary-black);
  display: none;
}
