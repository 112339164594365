.flow-accordion {
  color: var(--black);

  // Force higher specificity over bootstrap default stylings
  .flow-card {
    &#{&} {
      @include radius();
    }

    // Force rounded corners to override bootstrap
    &:not(:last-of-type),
    &:not(:first-of-type) {
      @include radius();
      border-bottom: 1px solid var(--color-line);
    }
  }

  > .flow-card--locked {
    &#{&} {
      border: 1px solid var(--color-locked-line);
    }
  }
}

.flow-card {
  background: var(--white);

  & + & {
    margin-top: 1rem;
  }
}

.flow-card--locked {
  background-color: var(--color-locked-line);
  border: 1px solid var(--color-locked-line);
  opacity: 0.5;
  color: var(--color-locked-text);

  .flow-card__header {
    background-color: var(--color-locked-line);
  }

  .card-body {
    background-color: var(--color-locked-line);
  }

  .pill--locked {
    border: 1px solid var(--color-locked-text);
    color: var(--color-locked-text);
  }
}

.flow-card__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  // Override default .card-header stylings
  background-color: var(--white);
  border-top: 0;
  border-left: 0;
  border-right: 0;

  @media (min-width: $bp-sm) {
    padding-bottom: 0.75rem;
  }

  > svg {
    transform-origin: center;
    transition: transform 0.15s ease-in-out;
  }

  &.is-open {
    > svg {
      transform: rotate(-180deg);
    }
  }
}

.flow-card__heading {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 1rem;

  @media (min-width: $bp-sm) {
    flex-grow: 1;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .flow-card__text {
    margin-bottom: 0.2rem;
    text-transform: capitalize;
    @media (min-width: $bp-sm) {
      margin-bottom: 0;
    }
  }

  // Align the title and progress vertically center
  h2 {
    align-items: center;
    display: inline;
    font-size: rem(17px);

    @media (min-width: $bp-sm) {
      margin-bottom: 0;
      font-size: rem(20px);
    }
  }

  h3 {
    @media (min-width: $bp-sm) {
      margin-bottom: 0;
    }
  }
}

.flow-card__progress {
  display: inline;
  font-family: var(--font-body);
  font-size: rem(14px);
  opacity: 0.6;
  display: inline-block;
  vertical-align: text-bottom;
  margin-left: 0.2rem;

  @media (min-width: $bp-sm) {
    vertical-align: text-bottom;
    padding-top: 2px;
  }
}
