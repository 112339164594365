dl.rates {
  display: flex;
  flex-direction: column;
  background: var(--color-line-light);

  & > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--color-line);

    &:last-child {
      border: none;
    }
  }

  dt {
    padding: 0.75rem;
    font-size: rem(14px);
    font-weight: normal;
  }

  dd {
    margin-bottom: 0;
    padding: 0.75rem;
  }
}
