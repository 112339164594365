.document-scan {
  .illustration {
    display: block;
    margin: 20px auto;
  }

  .scanner {
    transform-origin: center;
    animation: scale 2s infinite ease-in alternate;
  }

  ol {
    li {
      margin-bottom: 1rem;
    }
  }

  .info-panel {
    background: var(--off-white);
  }

  input[type="file"] {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;

    &:disabled + label {
      background-color: var(--secondary-white);
      cursor: not-allowed;
    }
  }
}

@keyframes scale {
  from {
    transform: scale(1.2);
  }

  to {
    transform: scale(1);
  }
}
