.page {
  min-height: 100vh;
  overflow: hidden;

  &.user {
    background-color: var(--color-page-bg);
  }

  &.admin {
    background-color: var(--color-body-bg);
  }
}
