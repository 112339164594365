// Sizing
@mixin size($width, $height: $width) {
  width: $width;
  height: $height;
}

// Clearfix
@mixin clearfix() {
  &::after {
    display: block;
    content: "";
    clear: both;
  }
}

// Border radius
@mixin radius($border-radius: $radius) {
  border-radius: $border-radius;
}

@mixin disabled {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.4;
  transition: opacity 0.2s ease;
}

@mixin link-alt {
  color: var(--color-body-text);

  &:hover {
    color: var(--color-body-text);
  }
}
