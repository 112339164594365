.home {
  min-height: calc(100vh - 74px);
  overflow: hidden;

  .logo-main {
    height: 32px;
    width: 110px;
    margin: 2.5rem auto 2rem auto;
    @media (min-width: $bp-lg) {
      height: 40px;
      width: 150px;
      margin-bottom: 2.5rem;
    }
  }

  .home__header {
    color: var(--color-home-header, var(--white));

    @media (min-width: $bp-lg) {
      margin-top: 2.5rem;
    }
  }

  .home__header-alert {
    @media (min-width: $bp-lg) {
      margin-top: 3rem;
    }
  }

  section {
    max-width: $max-width;
    margin: 2rem 1rem 2rem;

    @media (min-width: $bp-md) {
      margin-left: auto;
      margin-right: auto;
    }
  }

  .h1-alt {
    max-width: 500px;
    margin: 1rem auto;
  }
}

.task-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: -0.5rem 0 0.5rem -0.5rem;

  @media (min-width: $bp-sm) {
    margin: -1rem 0 1rem -1rem;
  }

  > div {
    flex: 0 0 100%;
    padding: 0.5rem 0 0 0.5rem;

    @media (min-width: $bp-sm) {
      flex-basis: 50%;
      padding: 1rem 0 0 1rem;
    }
    @media (min-width: $bp-md) {
      flex-basis: 33.33%;
    }
  }
}

.login-container {
  h1 {
    font-family: var(--font-primary);
    color: var(--black);
  }
}
