// Overriding bootstrap default stylings

.modal-content {
  border-radius: 5px;
  background-color: var(--color-body-bg);

  .dark-mode & {
    background-color: var(--color-body-text);
  }
}

.modal-title {
  font-family: var(--font-primary);
  font-size: 20px;
  line-height: 1.2;

  @media (min-width: $bp-sm) {
    font-size: 22px;
  }
}

.modal-header {
  padding: 1.5rem 2rem 1rem;
  border-bottom: 0;

  @media (min-width: $bp-sm) {
    padding: 1.5rem 2rem 1rem;
  }

  .dark-mode & {
    color: var(--white);
  }

  .button {
    .dark-mode & {
      color: var(--secondary-white);
    }
  }

  .close {
    padding: 0.75rem; // Adjusted to be inline with revised h4 font-size
    color: var(--color-body-text);
    opacity: 1;
    font-weight: 300;
    font-size: 2rem;

    .dark-mode & {
      color: var(--secondary-white);
    }
  }
}

.modal-body {
  padding: 0 2rem 1rem;

  @media (min-width: $bp-sm) {
    padding: 0 2rem 2rem;
  }

  .content-container {
    @include radius();
    max-height: 50vh;
    padding: 1rem;
    border: 1px solid var(--color-line);
    overflow: auto;
  }

  .dark-mode & {
    color: var(--white);
  }

  hr {
    .dark-mode & {
      border-color: var(--white-15);
    }
  }
}

.modal-footer {
  padding-left: 2rem;
  padding-right: 2rem;
  justify-content: flex-start;

  .dark-mode & {
    border-color: var(--white-15);
  }
}

.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;

  .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
}
