//--------- DEFAULT DARK MODE ----------//

.dark-mode .admin {
  // Header
  --color-header: #1c1c1c;
  --color-header-text: var(--white);

  // Nav
  --color-sidenav-bg: transparent;

  // Body
  --color-body-bg: #1c1c1c;
  --color-body-text: var(--white);
  --color-body-text-inverse: var(--black);
  --color-secondary-body-text: var(--secondary-white);
  --color-secondary-body-bg: #111;
  --color-tertiary-text: var(--white-60);

  // Lines
  --color-line: var(--darker-line);
  --color-line-light: var(--dark-line);

  // Input
  --color-input-bg: #111;

  // Chevron
  --color-chevron-progress: var(--blue-on-dark);
  --color-chevron-pending: var(--yellow);
  --color-chevron-completed: var(--green-on-dark);
  --color-chevron-progress-text: var(--white);
  --color-chevron-pending-text: var(--black);
  --color-chevron-completed-text: var(--white);

  // Misc
  --color-hover: rgba(255, 255, 255, 0.1);

  // Background
  --hamburger: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgb%28255, 255, 255%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
