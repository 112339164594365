.table {
  border-collapse: separate;
  border-spacing: 0;
  border-radius: 4px;

  @media (min-width: $bp-md) {
    border: 1px solid var(--color-line);
  }

  thead th {
    &:first-child {
      border-top-left-radius: 4px;
    }
    &:last-child {
      border-top-right-radius: 4px;
    }

    padding-top: 1rem;
    padding-bottom: 1rem;
    // border-bottom-width: 1px;
    color: var(--color-table-head, --color-body-text);
    font-weight: 700;
    border: none;
  }

  tr {
    background-color: var(--color-body-bg);
    color: var(--color-body-text);
    transition: background-color 0.5s;
  }

  tbody tr:hover {
    background-color: var(--color-hover-highlight);
  }

  th,
  td {
    border-color: var(--color-line);
    @media (min-width: $bp-lg) {
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
  }

  .dark-mode & {
    th,
    td {
      color: var(--color-body-text);
    }

    thead th {
      border-color: var(--color-line);
    }
  }

  .col-alert-dot {
    width: 2rem;
    padding-left: 1rem;
  }

  .col-name {
    width: 150px;

    @media (min-width: $bp-xl) {
      width: 200px;
    }
  }
  .col-state {
    min-width: 60px;
    // text-align: center;
  }

  .col-date {
    min-width: 110px;
    // width: 120px;
  }

  .col-progress {
    width: 150px;
    padding-right: 2rem;
  }

  .col-shield {
    text-align: center;

    svg {
      &.is-text-success {
        color: var(--color-success-wcag) !important;
      }
      color: var(--color-error);
    }
  }
}

.react-bootstrap-table {
  .table-row {
    cursor: pointer;
  }
}
