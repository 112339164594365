.list-styled {
  list-style: initial;

  li {
    margin-left: 1rem;
    margin-bottom: 0.5rem;
  }
}

ol.list-alpha {
  list-style: lower-alpha;
}
