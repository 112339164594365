//--------- BEAM THEME ----------//

// Light
.beam .admin {
  // Brand
  --color-page-bg: var(--pink);
  --color-spinner: var(--orangey-red);

  // Fonts
  --font-display: "DIN Next Rounded Bold", sans-serif;
  --font-primary: "DIN Next Rounded Medium", sans-serif;
  --font-secondary: "DIN Next Rounded Light", sans-serif;
  --font-body: "Roboto", sans-serif;

  // Header
  --color-header: var(--white);
  --color-header-text: var(--black);
  --color-heading-text: #d03939;
  --color-page-header-arrow: #ef7026;

  // Body
  --color-body-bg: var(--white);
  --color-body-text: var(--black);
  --color-body-text-alt: var(--grey-blue);
  --color-body-text-inverse: var(--white);
  --color-secondary-body-bg: var(--grey-light);
  --color-secondary-body-text: var(--secondary-black);

  // Buttons
  --color-btn-primary: var(--orangey-red);

  // Lines
  --color-line: var(--light-line);
  --color-line-light: var(--lighter-line);

  // Statuses
  --color-default: var(--lighter-line);
  --color-error: var(--orangey-red);
  --color-warning: var(--yellow);
  --color-success: var(--green);

  --color-input-bg: var(--off-white);
  --color-highlight: var(--teal);

  // Chevron colours
  --color-chevron-progress: var(--blue-on-light);
  --color-chevron-pending: var(--yellow);
  --color-chevron-completed: var(--green);
  --color-chevron-progress-text: var(--white);
  --color-chevron-pending-text: var(--black);
  --color-chevron-completed-text: var(--white);
}

// Dark
.beam.dark-mode .admin {
  // Header
  --color-header: var(--blue);

  // Body
  --color-body-bg: var(--grey-dark);
  --color-body-text: var(--white);
  --color-body-text-inverse: var(--black);
  --color-secondary-body-text: var(--secondary-white);
  --color-secondary-body-bg: var(--navy-black);

  // Lines
  --color-line: var(--darker-line);
  --color-line-light: var(--dark-line);

  // Input
  --color-input-bg: var(--navy-black);

  // Chevron
  --color-chevron-progress: var(--blue-on-dark);
  --color-chevron-pending: var(--yellow);
  --color-chevron-completed: var(--green-on-dark);
  --color-chevron-progress-text: var(--white);
  --color-chevron-pending-text: var(--black);
  --color-chevron-completed-text: var(--white);
}
