.page-body {
  max-width: $max-width;
  margin: 0 auto;
  padding: 1.75rem 1rem;
  background: var(--white);

  @media (min-width: $bp-sm) {
    padding: 2.5rem;
  }

  @media (min-width: $max-width) {
    @include radius();
    margin-bottom: 2.5rem;
  }

  .dark-mode & {
    background: none;
  }
}

.page-body--narrow {
  max-width: 480px;
}
