@media (min-width: $bp-lg) {
  .candidate-profile__middle-col {
    min-height: calc(100vh - 74px);
    border-left: 1px solid var(--color-line);
    border-right: 1px solid var(--color-line);

    h1,
    .candidate-profile__new-flow {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }
  }

  // Side columns fixed when scrolling
  .candidate-profile__left-col,
  .candidate-profile__right-col {
    position: fixed;
    max-width: inherit; // Inherit the max-widht from the containing column
    z-index: 1;
  }
}

.candidate-profile__avatar {
  @include size(7rem);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  background-color: var(--color-btn-primary);
  border-radius: 50%;
  // border: 2px solid var(--color-line);
  overflow: hidden;
  font-size: 2.5rem;
  // font-weight: 700;
  color: var(--color-btn-primary-text);
  font-family: var(--font-display);

  img {
    object-fit: cover;
  }
}

.candidate-profile__heading {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
}

.candidate-profile__actions {
  max-width: 300px;
  padding-left: 0;
  padding-right: 0;

  @media (min-width: $bp-xl) {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

.candidate-profile__fixed-header {
  padding-top: 1rem;
  border-bottom: 1px solid var(--color-line);

  @media (min-width: $bp-lg) {
    position: sticky;
    top: 73px;
    width: 100%;
    background-color: var(--color-body-bg);
    z-index: 1;
  }
}

.flow-panel {
  border: 1px solid var(--color-line);
  border-bottom: none;

  &:last-child {
    border-bottom: 1px solid var(--color-line);
  }

  @media (min-width: $bp-lg) {
    border: none;
    border-top: 1px solid var(--color-line);
  }

  .flow-panel__heading {
    padding: 0.5rem 0rem 0.5rem 1.5rem;
    background: var(--color-line-light);
    border-bottom: 1px solid var(--color-line);
    margin-right: 0;

    .dropdown-menu {
      background: var(--color-body-bg);
      border: 1px solid var(--color-line);
      padding: 0;
      .dropdown-item {
        color: var(--color-body-text);
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;
        &:hover {
          background: var(--color-line-light);
        }
      }
    }
  }

  .flow-panel__body {
    padding: 1.5rem;
    &.is-disabled {
      @include disabled;
      & > ol > li > div {
        background-color: var(--color-line-light);
      }
    }
  }
}

.candidate-profile__task-item {
  @include radius();
  margin-bottom: 1rem;
  padding: 1rem 1.5rem;
  border: 1px solid var(--color-line);
  border-left: 4px solid var(--color-line);
  color: var(--color-body);

  &.is-complete {
    border-left-color: var(--color-success);
  }

  &.is-not-ready {
    border-left-color: var(--color-line);
    opacity: 0.3;
    pointer-events: none;
    background-color: var(--color-line-light);
  }

  &.is-pending {
    border-left-color: var(--color-warning);
  }

  &.is-rejected {
    border-left-color: var(--color-error);
  }

  h3 {
    margin-bottom: 0;
  }

  button.icon {
    svg {
      color: var(--color-body-text);
    }
  }

  dl {
    li {
      margin-bottom: 0.5rem;
    }

    dt {
      float: left;
      color: var(--color-secondary-body-text);
      font-size: rem(14px);
      margin-right: 0.5rem;
    }
  }
}

.candidate-profile__qualification_container {
  margin: 10px 0;
  padding: 15px 15px 15px;
  // background: var(--off-white);
  position: relative;
  border: 1px solid var(--color-line);

  form {
    width: 100%;
    margin-top: -40px;
    overflow: hidden;

    label {
      margin-bottom: 0;
    }

    input {
      margin-bottom: 10px;
    }
  }
}

.candidate-profile__template-download {
  padding: 0.5rem 1rem;
  background-color: var(--color-line-light);

  .candidate-profile__template-pill {
    flex-shrink: 0;
    padding: 0.2rem 0.6rem;
    border-radius: 4px;
    border: 1px solid var(--color-line);
    font-size: rem(12px);
    text-align: center;
    color: var(--color-secondary-body-text);
    cursor: default;
  }

  .file-name {
    text-align: left;

    &:hover {
      text-decoration: underline;
    }
  }
}

.candidate-profile__right-col {
  @media (min-width: $bp-lg) {
    margin-top: 3rem;
    border: 1px solid var(--color-line);
    // padding: 2rem 1rem 1rem 1.5rem;
    @include radius();
    width: 220px;
  }
  @media (min-width: $bp-xl) {
    width: 260px;
    // padding: 2rem 2rem 1rem;
  }
}

.candidate-profile__left {
  margin-top: 2rem;
  @media (min-width: $bp-lg) {
    margin: 3rem 0 0 1rem;
  }
  @media (min-width: $bp-xl) {
    margin: 3rem 0 0 2rem;
  }
}

.contract-timeline {
  li:last-of-type .contract-timeline__item:before {
    content: none;
  }
}

.contract-timeline__item {
  display: flex;
  flex-direction: row;
  position: relative;
  padding: 0.5rem 1.5rem;

  &:hover {
    text-decoration: none;
    // background-color: var(--color-line-light);

    .contract-timeline__title {
      text-decoration: underline;
    }
  }

  &:before {
    content: "";
    position: absolute;
    top: 38px;
    left: 33px;
    width: 2px;
    height: calc(100% - 24px);
    background-color: var(--color-line-light);
  }

  p {
    margin-bottom: 0;
  }

  &.active {
    font-weight: bold;
  }
}

.contract-timeline__title {
  margin-bottom: 0.25rem;
  line-height: 1.25;
  @include link-alt;
  &:hover {
    text-decoration: underline;
  }
}

.contract-timeline__dot {
  @include size(20px);
  flex-shrink: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  margin-right: 0.5rem;
  background-color: var(--color-body-bg);

  &:after {
    content: "";
    @include size(12px);
    border-radius: 100%;
    background-color: var(--color-line);
  }

  &.is-verifying:after {
    background-color: var(--color-warning);
  }

  &.is-active:after {
    background-color: var(--color-success);
  }

  &.is-expired:after {
    background-color: var(--color-line);
  }

  .contract-timeline__item.active &,
  &.is-selected {
    background-color: var(--color-line);

    &.is-verifying {
      background-color: var(--color-warning);
    }

    &.is-active {
      background-color: var(--color-success);
    }

    &.is-expired {
      background-color: var(--color-line);
    }

    &:after {
      @include size(12px);
      background-color: var(--color-body-bg);
    }
  }
}

.contract-date {
  color: var(--color-tertiary-text);
  font-size: 14px;
}

.contract-title-bar {
  flex-direction: column;

  @media (min-width: $bp-lg) {
    flex-direction: row;
  }

  &__title {
    width: 100%;
    padding: 1rem 1.5rem;

    .contract-date {
      padding-left: 0.5rem;
    }

    @media (min-width: $bp-sm) {
      .contract-date {
        display: inline-block;
        margin: 0;
      }
    }
  }

  &__menu {
    display: flex;
    align-items: center;
    border-top: 1px solid var(--color-line);
    width: 100%;
    padding: 1rem 1.5rem;
    text-align: center;

    @media (min-width: $bp-lg) {
      border: none;
      width: auto;
    }
  }

  &__details {
    border-right: 1px solid var(--color-line);
    padding-right: 1rem;
  }
}
