.page-header {
  padding: 1rem;
  background-color: transparent;

  @media (min-width: $bp-sm) {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .page-header-container {
    max-width: $max-width;
    display: flex;
    align-items: flex-start;
    color: var(--color-page-header, var(--white));

    @media (min-width: $bp-sm) {
      margin: 0 auto;
    }
  }

  .page-header__heading {
    display: flex;
    align-items: flex-start;

    @media (min-width: $bp-sm) {
      align-items: center;
    }

    h1 {
      margin-bottom: 0;
      line-height: 1.4;
    }
  }

  .page-header__back {
    padding: 0 0.3rem 0 0;

    color: var(--color-page-header, var(--white));

    svg {
      @include size(1.5rem);
      transition: transform 0.2s ease-in-out;
      color: var(--color-page-header-arrow, var(--color-page-header));
    }

    &:hover {
      svg {
        transform: translateX(-0.25rem);
      }
    }

    @media (min-width: $bp-sm) {
      padding: 0 0.6rem 0 0.1rem;
      svg {
        @include size(1.6rem);
        margin-bottom: 6px;
      }
    }
  }

  .page-header__subheading {
    margin-left: 2rem;
    margin-bottom: 0;

    @media (min-width: $bp-sm) {
      margin-left: 2.5rem;
    }
  }
}
