@import "~@fontsource/roboto/scss/mixins";
@include fontFaceCustom(
  $weight: 300,
  $display: swap,
  $woff2Path: "~@fontsource/roboto/files/roboto-latin-300-normal.woff2",
  $unicodeRange: true
);
@include fontFaceCustom(
  $weight: 400,
  $display: swap,
  $woff2Path: "~@fontsource/roboto/files/roboto-latin-400-normal.woff2",
  $unicodeRange: true
);
@include fontFaceCustom(
  $weight: 500,
  $display: swap,
  $woff2Path: "~@fontsource/roboto/files/roboto-latin-500-normal.woff2",
  $unicodeRange: true
);

@font-face {
  font-family: "DIN Next Rounded";
  src: url(../../assets/fonts/DINNextRoundedLTPro-Regular.woff2) format("woff2"),
    url(../../assets/fonts/DINNextRoundedLTPro-Regular.woff) format("woff");
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "DIN Next Rounded Medium";
  src: url(../../assets/fonts/DINNextRoundedLTPro-Medium.woff2) format("woff2"),
    url(../../assets/fonts/DINNextRoundedLTPro-Medium.woff) format("woff");
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "DIN Next Rounded Bold";
  src: url(../../assets/fonts/DINNextRoundedLTPro-Bold.woff2) format("woff2"),
    url(../../assets/fonts/DINNextRoundedLTPro-Bold.woff) format("woff");
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: "DIN Next Rounded Light";
  src: url(../../assets/fonts/DINNextRoundedLTPro-Light.woff2) format("woff2"),
    url(../../assets/fonts/DINNextRoundedLTPro-Light.woff) format("woff");
  font-weight: 300;
  font-display: swap;
}

body {
  font-family: var(--font-body);
}

$body-size: rem(16px);
$sm-size: rem(14px);

.title {
  font-family: var(--font-display);
  font-size: rem(30px);
  font-weight: 400;
  line-height: 1.3;
  letter-spacing: -0.4px;

  @media (min-width: $bp-sm) {
    font-size: rem(42px);
  }
}

h1,
.h1 {
  font-family: var(--font-display);
  font-size: rem(24px);
  line-height: 1.3;
  font-weight: 400;
  color: var(--color-red-dark);

  @media (min-width: $bp-sm) {
    font-size: rem(30px);
    line-height: 1.3;
  }
}

.h1-alt {
  font-family: var(--font-secondary);
  font-size: rem(20px);
  line-height: 1.6rem;
  font-weight: 300;
  letter-spacing: -0.5px;
  margin-top: 2px;

  @media (min-width: $bp-sm) {
    font-size: rem(24px);
    line-height: 2rem;
  }
}

h2,
.h2 {
  font-family: var(--font-primary);
  font-size: rem(18px);
  line-height: 1.6;
  font-weight: 500;

  @media (min-width: $bp-sm) {
    font-size: rem(22px);
    font-weight: 500;
  }
}

h3,
.h3 {
  font-family: var(--font-secondary);
  font-size: rem(16px);
  line-height: 1.6;

  @media (min-width: $bp-sm) {
    font-size: rem(20px);
    font-weight: 300;
  }
}

h4,
.h4 {
  font-family: var(--font-body);
  font-size: rem(16px);
  line-height: 1.6;
  font-weight: 500;

  @media (min-width: $bp-sm) {
    font-size: rem(18px);
    font-weight: 500;
  }
}

p,
.p {
  font-family: var(--font-body);
  font-size: $body-size;
  line-height: 1.6;
  font-weight: 400;
}

.p--sm {
  font-size: rem(14px);
  color: var(--color-secondary-body-text);
}

.p--leading {
  font-family: var(--font-body);
  font-size: rem(18px);
  font-weight: 300;

  @media (min-width: $bp-sm) {
    font-size: rem(20px);
  }
}

.a--underline {
  text-decoration: underline;
}

small {
  font-size: $sm-size;
}

.definition-term {
  color: var(--color-body-text-alt);
  font-size: rem(14px);
  font-weight: 500;
}

ol.number-list {
  list-style-type: decimal;
  list-style-position: inside;

  li {
    list-style: inherit;
  }
}
