$color-statuses: default, error, warning, success, info;

@each $status in $color-statuses {
  .is-bg-#{$status} {
    background-color: var(--color-#{$status}) !important;
  }

  .is-border-#{$status} {
    border-color: var(--color-#{$status}) !important;
  }

  .is-text-#{$status} {
    color: var(--color-#{$status}) !important;
  }
}
